import {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
  SelectHTMLAttributes,
} from 'react';

import { ErrorSpan, WithLabel } from '@components/Label';
import { ArrowDown } from '@icons/svg';
import type { WithLabelType } from '@utils/type';

export interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'> {
  onChange: ChangeEventHandler<HTMLSelectElement>;
  children: ReactNode;
  size?: keyof typeof STYLE_VARIANTS.size;
  width?: string;
  disabled?: boolean;
  error?: string;
  className?: string;
}

const STYLE_VARIANTS = {
  size: {
    small: 'text-[1.2rem] h-[3.4rem]',
    medium: 'text-[1.4rem] h-[4rem]',
    large: 'text-[1.6rem] h-[6rem]',
  },
} as const;

export const Select = forwardRef(
  (
    {
      children,
      width = 'w-full',
      size = 'medium',
      disabled = false,
      error,
      className,
      ...rest
    }: SelectProps,
    ref: ForwardedRef<HTMLSelectElement>
  ) => {
    return (
      <>
        <div
          className={`${STYLE_VARIANTS.size[size]} ${width} ${
            !error ? 'border-grey-600 bg-white' : 'border-red-100 bg-pink-30'
          } relative flex items-center border-solid border-[0.1rem] rounded-lg text-black-400`}
        >
          <select
            ref={ref}
            className={`appearance-none px-[1.4rem] w-full h-full outline-none rounded-lg disabled:bg-gray-200 whitespace-nowrap text-ellipsis bg-transparent ${className}`}
            disabled={disabled}
            {...rest}
          >
            {children}
          </select>
          <ArrowDown className='absolute right-[1.4rem] pointer-events-none' />
        </div>
        <ErrorSpan error={error} />
      </>
    );
  }
);

export function SelectLabel({ label, id, ...rest }: WithLabelType<SelectProps>) {
  return (
    <WithLabel label={label} id={id}>
      <Select {...rest} />
    </WithLabel>
  );
}
