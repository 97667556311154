export const SHEET_쿠폰_정산_상세_다운로드_VENDOR = {
  name: '쿠폰정산상세',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '주문번호' },
    { start: 'B1', end: 'B1', title: '상품유형' },
    { start: 'C1', end: 'C1', title: '판매상품코드' },
    { start: 'D1', end: 'D1', title: '옵션코드' },
    { start: 'E1', end: 'E1', title: '상품명' },
    { start: 'F1', end: 'F1', title: '옵션명' },
    { start: 'G1', end: 'G1', title: '옵션값' },
    { start: 'H1', end: 'H1', title: '쿠폰 매출액' },
    { start: 'I1', end: 'I1', title: '쿠폰코드' },
    { start: 'J1', end: 'J1', title: '쿠폰명' },
    { start: 'K1', end: 'K1', title: '쿠폰 할인액/율' },
    { start: 'L1', end: 'L1', title: '최대할인금액' },
    { start: 'M1', end: 'M1', title: '쿠폰 사용금액' },
    { start: 'N1', end: 'N1', title: '주문일시' },
    { start: 'O1', end: 'O1', title: '구매확정일시' },
    { start: 'P1', end: 'P1', title: '취소일시' },
  ],
};

export const SHEET_쿠폰_정산_상세_다운로드_ADMIN = {
  name: '쿠폰정산상세',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '거래처코드' },
    { start: 'B1', end: 'B1', title: '거래처명' },
    { start: 'C1', end: 'C1', title: '주문번호' },
    { start: 'D1', end: 'D1', title: '상품유형' },
    { start: 'E1', end: 'E1', title: '판매상품코드' },
    { start: 'F1', end: 'F1', title: '옵션코드' },
    { start: 'G1', end: 'G1', title: '상품명' },
    { start: 'H1', end: 'H1', title: '옵션명' },
    { start: 'I1', end: 'I1', title: '옵션값' },
    { start: 'J1', end: 'J1', title: '쿠폰 매출액' },
    { start: 'K1', end: 'K1', title: '쿠폰코드' },
    { start: 'L1', end: 'L1', title: '쿠폰명' },
    { start: 'M1', end: 'M1', title: '쿠폰 할인액/율' },
    { start: 'N1', end: 'N1', title: '최대할인금액' },
    { start: 'O1', end: 'O1', title: '쿠폰 사용금액' },
    { start: 'P1', end: 'P1', title: '주문일시' },
    { start: 'Q1', end: 'Q1', title: '구매확정일시' },
    { start: 'R1', end: 'R1', title: '취소일시' },
  ],
};
