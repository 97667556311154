import { FunctionComponent, SVGProps, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Flex, Typo } from '@teampetfriends/react-ui';

import { useAtom } from 'jotai';

import {
  MenuAnnounceInactive,
  MenuArrowDown,
  MenuArrowUp,
  MenuCouponInactive,
  MenuDeliveryActive,
  MenuDeliveryInactive,
  MenuProductActive,
  MenuProductInactive,
  MenuSettingInactive,
  MenuSettlementInactive,
  MenuSupportInactive,
} from '@icons/svg';
import { visibleAtom } from '@store/atoms';
import { convertObjectToQueryString } from '@utils/queryString';
import { HistoryCache } from '@utils/util';

import NewBadge from '../NewBadge';

interface AccordionProps {
  name: string;
  ico: string;
  subMenu: { name: string; path: string; visible: boolean; params?: unknown }[];
  isNew: boolean;
}

const components: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  MenuDeliveryInactive,
  MenuProductInactive,
  MenuDeliveryActive,
  MenuProductActive,
  MenuSupportInactive,
  MenuSettingInactive,
  MenuAnnounceInactive,
  MenuCouponInactive,
  MenuSettlementInactive,
};

function Accordion({ subMenu, name, ico, isNew }: AccordionProps) {
  const history = new HistoryCache();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [visible] = useAtom(visibleAtom);

  const [active, setActive] = useState(false);

  const IcoComponent = components[`${ico}Inactive`] as FunctionComponent<SVGProps<SVGSVGElement>>;

  const subMenuList = useMemo(() => {
    if (visible === 'invisible') {
      return subMenu.filter((item) => item.visible);
    }
    return subMenu;
  }, [subMenu, visible]);

  const initOpenMenuControl = () => {
    const targetUrls = subMenu.map(({ path }) => path).flat();

    const isTargetArr: boolean[] = [];

    targetUrls.forEach((paths) => {
      isTargetArr.push(pathname.includes(paths));
    });

    setActive(isTargetArr.some((bool) => bool));
  };

  const onClickRoute = (path: string, params: unknown) => {
    history.clear();

    const queryString = convertObjectToQueryString(params || {});

    navigate({
      pathname: path,
      search: queryString,
    });

    // if (CONFIRM_URLS.some((url) => isIncludePath(url, pathname))) {
    //   // TODO: 상품등록 나가기 전에 알럿을 띄울지 PO와 얘기한 후 논의
    // } else {
    //   navigate(path);
    // }
  };

  useEffect(() => {
    initOpenMenuControl();
  }, [pathname]);

  return (
    <>
      <Flex
        align='center'
        justify='between'
        css={{ padding: '1.8rem 1.6rem', borderBottom: '.1rem solid #E9EBEC' }}
        onClick={() => setActive((prev) => !prev)}
      >
        <Flex align='center' css={{ columnGap: '.8rem', cursor: 'pointer' }}>
          <Flex align='center' justify='center' css={{ width: '2rem', height: '2rem' }}>
            <IcoComponent />
          </Flex>
          <Flex align='center' css={{ columnGap: '.6rem' }}>
            <Typo css={{ fontSize: '1.6rem', color: '#2D3035', fontWeight: 700 }}>{name}</Typo>
            {isNew && <NewBadge />}
          </Flex>
        </Flex>
        {active ? <MenuArrowDown /> : <MenuArrowUp />}
      </Flex>
      <Box
        as='ul'
        css={{
          backgroundColor: '#FAFAFA',
          padding: active ? '1.2rem 0' : 0,
          borderBottom: active ? '.1rem solid #E9EBEC' : 'none',
          height: active ? `${subMenuList.length * 4.2 + 2.4}rem` : '0rem',
          overflow: 'hidden',
          transition: 'all .4s',
        }}
      >
        {subMenuList.map(({ name, path, params }) => (
          <Flex
            as='li'
            key={name}
            align='center'
            css={{
              padding: '0 2.4rem',
              height: '4.2rem',
              '&>button': {
                color: pathname.includes(path) ? '#FF4081' : '#757C89',
                fontWeight: pathname.includes(path) ? 700 : 400,
              },
            }}
          >
            <button
              type='button'
              onClick={() => onClickRoute(path, params)}
              className='block text-[1.4rem] text-left text-black-50 w-full h-full leading-[4.2rem] font-normal'
            >
              {name}
            </button>
          </Flex>
        ))}
      </Box>
    </>
  );
}

export default Accordion;
