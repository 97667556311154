import { useMemo } from 'react';

import dayjs from 'dayjs';

import { type GetNoticeListResponse } from '@notice/NoticeInquire/modules/interfaces';
import { useQuery } from '@tanstack/react-query';
import { client } from '@utils/client';
import { getListQueryStringMaker } from '@utils/util';

import type {
  AdminSalesRankingItem,
  GetClaimSummaryWithDashboardResponse,
  GetDeliverySummaryWithDashboardResponse,
  GetRequestSummaryWithDashboardResponse,
  GetSettlementSummaryWithDashboardResponse,
  VendorSalesRankingItem,
} from '../interfaces';

const getNoticeListWithDashboard = async () => {
  const { data } = await client(true).get<GetNoticeListResponse>('/portals/notices/dashboard');

  return data;
};

export const useGetNoticeListWithDashboard = () => {
  const { data } = useQuery({
    queryKey: ['notice', 'list', 'dashboard'],
    queryFn: getNoticeListWithDashboard,
    keepPreviousData: true,
  });

  return { noticeList: data?.noticeResponses ?? [] };
};

const getDeliverySummaryWithDashboard = async () => {
  const url = getListQueryStringMaker('/delivery/dashboard/summary', {
    startDate: null,
    endDate: null,
  });

  const { data } = await client(true).get<GetDeliverySummaryWithDashboardResponse>(url);

  return data;
};

export const useGetDeliverySummaryWithDashboard = () => {
  const { data } = useQuery({
    queryKey: ['delivery', 'summary', 'dashboard'],
    queryFn: getDeliverySummaryWithDashboard,
    keepPreviousData: true,
  });

  return {
    data: data ?? {
      orderCompletedCount: 0,
      deliveryDraftCount: 0,
      deliveryProcessCount: 0,
      delayedDeliveryCompleteCount: 0,
    },
  };
};

const getClaimSummaryWithDashboard = async () => {
  const url = getListQueryStringMaker('/claims/dashboard/summary', {
    startDate: null,
    endDate: null,
  });

  const { data } = await client(true).get<GetClaimSummaryWithDashboardResponse>(url);

  return data;
};

export const useGetClaimSummaryWithDashboard = () => {
  const { data } = useQuery({
    queryKey: ['claim', 'summary', 'dashboard'],
    queryFn: getClaimSummaryWithDashboard,
    keepPreviousData: true,
  });

  return {
    data: data ?? {
      exchangeRequestedCount: 0,
      returnRequestedCount: 0,
      delayedCollectionInvoiceCount: 0,
      delayedCompletedCount: 0,
    },
  };
};

export const getSettlementSummaryWithDashboard = async () => {
  const url = getListQueryStringMaker('/settlements/dashboard/summary', {
    yearMonth: dayjs(new Date()).format('YYYY-MM'),
  });

  const { data } = await client(true).get<GetSettlementSummaryWithDashboardResponse>(url);

  return data;
};

export const useGetSettlementSummaryWithDashboard = () => {
  const { data } = useQuery({
    queryKey: ['settlement', 'summary', 'dashboard'],
    queryFn: getSettlementSummaryWithDashboard,
    keepPreviousData: true,
  });

  return {
    data: data ?? {
      vendorFee: 0,
      consignmentFee: 0,
      couponFee: 0,
    },
  };
};

export const getRequestSummaryWithDashboard = async () => {
  const url = getListQueryStringMaker('/portals/dashboard/requests', {
    startDate: null,
    endDate: null,
  });

  const { data } = await client(true).get<GetRequestSummaryWithDashboardResponse>(url);

  return data;
};

export const useGetRequestSummaryWithDashboard = () => {
  const { data } = useQuery({
    queryKey: ['request', 'summary', 'dashboard'],
    queryFn: getRequestSummaryWithDashboard,
    keepPreviousData: true,
  });

  return {
    data: data ?? {
      productRegisterRequestedCount: 0,
      productConnectionRequestedCount: 0,
      productModifyRequestedCount: 0,
      productStatusRequestedCount: 0,
      productPriceRequestedCount: 0,
      inboundRequestedCount: 0,
      inboundApprovedCount: 0,
      couponIssueRequestedCount: 0,
      vendorBusinessModifyRequestedCount: 0,
      vendorSettlementModifyRequestedCount: 0,
    },
  };
};

export const getSalesRankingList = async <T extends 'ADMIN' | 'VENDOR'>(params: {
  sortCondition: string;
  checkUser: T;
}) => {
  const url = getListQueryStringMaker('/products/dashboard/sales-ranking', {
    sortCondition: params.sortCondition,
  });

  const { data } = await client(true).get<{
    productSalesRaking: (T extends 'ADMIN' ? AdminSalesRankingItem : VendorSalesRankingItem)[];
  }>(url);

  return data;
};

const SALES_RANKING_LIST_KEYS = (sortCondition: string) => [
  'sale',
  'ranking',
  'list',
  'dashboard',
  sortCondition,
];

export const useSalesRankingList = <T extends 'ADMIN' | 'VENDOR'>(params: {
  sortCondition: string;
  checkUser: T;
}) => {
  const { data } = useQuery<
    {
      productSalesRaking: (T extends 'ADMIN' ? AdminSalesRankingItem : VendorSalesRankingItem)[];
    },
    unknown,
    { productSalesRaking: (T extends 'ADMIN' ? AdminSalesRankingItem : VendorSalesRankingItem)[] },
    ReturnType<typeof SALES_RANKING_LIST_KEYS>
  >({
    queryKey: SALES_RANKING_LIST_KEYS(params.sortCondition),
    queryFn: () => getSalesRankingList(params),
    keepPreviousData: true,
  });

  const rankingList = useMemo(() => {
    return data?.productSalesRaking ?? [];
  }, [data?.productSalesRaking]);

  return { rankingList };
};
