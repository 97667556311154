import { ReactNode } from 'react';

import { Flex } from '@teampetfriends/react-ui';

import { ALARM_TABS } from '../constant';
import { useNotificationContext } from '../context/createNotificationContext';

import NotificationTabItem from './NotificationTabItem';

interface NotificationTabProps {
  rightNode?: ReactNode;
}

function NotificationTab({ rightNode }: NotificationTabProps) {
  const { tab, setTab } = useNotificationContext('NotificationTab');

  const onClickTabActive = (key: string) => {
    setTab(key);
  };

  return (
    <Flex align='center' justify='between' css={{ mt: '.8rem' }}>
      <Flex as='ul' align='center' css={{ columnGap: '.6rem' }}>
        {ALARM_TABS.map(({ key, value }) => (
          <NotificationTabItem
            label={value}
            key={key}
            active={key === tab}
            onClick={() => onClickTabActive(key)}
          />
        ))}
      </Flex>
      {rightNode}
    </Flex>
  );
}

export default NotificationTab;
