import type { Nullable } from '@teampetfriends/util-types';

import { atom, useAtom } from 'jotai';

import { useQuery } from '@tanstack/react-query';

import { settlementClientWithEnum } from '../../apis/settlementClient';

import type { OptionKeyWithSettlement, OptionResponseWithSettlement } from './modules/interfaces';

const GET_OPTIONS_WITH_SETTLEMENT_QUERY_KEY = (key: OptionKeyWithSettlement[]) =>
  ['option', 'settlement', JSON.stringify(key)] as const;

const getOptionsWithSettlement = async () => {
  const { data } = await settlementClientWithEnum(false).get<OptionResponseWithSettlement>(
    '/enum/items'
  );

  return data;
};

export const optionAtomWithSettlement = atom<Nullable<OptionResponseWithSettlement>>(null);

export const useOptionsWithSettlement = (
  key: OptionKeyWithSettlement[]
): OptionResponseWithSettlement[OptionKeyWithSettlement][] | [] => {
  const [options, setOptions] = useAtom(optionAtomWithSettlement);

  useQuery({
    queryKey: GET_OPTIONS_WITH_SETTLEMENT_QUERY_KEY(key),
    queryFn: getOptionsWithSettlement,
    enabled: !options,
    onSuccess: (res) => {
      setOptions(res);
    },
    onError: () => {
      setOptions(null);
    },
  });

  return options ? key.map((optionKey) => options[optionKey]) : [];
};
