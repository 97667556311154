import { useMemo } from 'react';

import { Flex, styled, Typo } from '@teampetfriends/react-ui';
import { jwtDecode } from '@teampetfriends/utils';

import Button from '@components/Button';
import ErrorBoundary from '@components/ErrorBoundary';
import { useLogout } from '@hooks/useLogout';
import { useAuthInfo } from '@hooks/useSyncAuth';
import { Menu } from '@icons/svg';
import { type TokenInfo, getTokenData } from '@utils/token';

import Alarm from './modules/components/Alarm';
import TopNotification from './modules/components/TopNotification';

const HeaderElement = styled(Flex, {
  position: 'fixed',
  background: '#fff',
  borderBottom: '.1rem solid #d1d6db',
  height: '5.7rem',
  padding: '0 1.4rem 0 4.2rem',
  top: 0,
  transition: 'all .3s',
  zIndex: 8,
});

function Header({ action, isOpen }: { action: () => void; isOpen: boolean }) {
  const logout = useLogout();

  const userData = getTokenData();

  const { storage } = useAuthInfo(['accessToken']);

  const userName = useMemo(() => {
    if (storage) {
      const userData = jwtDecode<TokenInfo>(storage);
      return userData?.user_name ?? '';
    }

    const user = getTokenData();

    return user?.user_name;
  }, [storage]);

  const onClickLogout = () => {
    logout();
  };

  return (
    <HeaderElement
      as='header'
      css={{
        width: `calc(100% - ${isOpen ? '25rem' : '0rem'})`,
        marginLeft: isOpen ? 250 : 0,
      }}
      align='center'
      justify='between'
    >
      <Flex align='center' css={{ columnGap: '2rem' }}>
        <Flex
          as='button'
          type='button'
          align='center'
          justify='center'
          css={{ width: '3.2rem', height: '4rem' }}
          onClick={action}
        >
          <Menu />
        </Flex>

        <ErrorBoundary renderFallback={() => null}>
          <TopNotification />
        </ErrorBoundary>
      </Flex>

      <Flex align='center' justify='between' css={{ columnGap: '$14' }}>
        <Typo as='span' css={{ fontSize: '$16', lineHeight: '4rem' }}>
          {userName}
        </Typo>
        {userData?.is_vendor && <Alarm />}
        <Button onClick={onClickLogout}>로그아웃</Button>
      </Flex>
    </HeaderElement>
  );
}

export default Header;
