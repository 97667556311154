export const SHEET_교환반품_공급가_다운로드_VENDOR = {
  name: '반품 공급가',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '접수일' },
    { start: 'B1', end: 'B1', title: '주문일' },
    { start: 'C1', end: 'C1', title: '접수번호' },
    { start: 'D1', end: 'D1', title: '주문번호' },
    { start: 'E1', end: 'E1', title: '상품명' },
    { start: 'F1', end: 'F1', title: '옵션명' },
    { start: 'G1', end: 'G1', title: '옵션값' },
    { start: 'H1', end: 'H1', title: '주문수량' },
    { start: 'I1', end: 'I1', title: '공급가' },
    { start: 'J1', end: 'J1', title: '실결제가' },
    { start: 'K1', end: 'K1', title: '배송완료일' },
    { start: 'L1', end: 'L1', title: '교환반품완료일' },
    { start: 'M1', end: 'M1', title: '교환반품 상태' },
    { start: 'N1', end: 'N1', title: '요청수량' },
    { start: 'O1', end: 'O1', title: '반품 공급가' },
  ],
};

export const SHEET_교환반품_배송비_다운로드_VENDOR = {
  name: '교환반품 배송비',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '접수일' },
    { start: 'B1', end: 'B1', title: '주문일' },
    { start: 'C1', end: 'C1', title: '교환반품 완료일' },
    { start: 'D1', end: 'D1', title: '접수번호' },
    { start: 'E1', end: 'E1', title: '주문번호' },
    { start: 'F1', end: 'F1', title: '사유' },
    { start: 'G1', end: 'G1', title: '배송비' },
  ],
};

export const SHEET_교환반품_공급가_다운로드_ADMIN = {
  name: '반품 공급가',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '거래처코드' },
    { start: 'B1', end: 'B1', title: '거래처명' },
    { start: 'C1', end: 'C1', title: '접수일' },
    { start: 'D1', end: 'D1', title: '주문일' },
    { start: 'E1', end: 'E1', title: '접수번호' },
    { start: 'F1', end: 'F1', title: '주문번호' },
    { start: 'G1', end: 'G1', title: '상품명' },
    { start: 'H1', end: 'H1', title: '옵션명' },
    { start: 'I1', end: 'I1', title: '옵션값' },
    { start: 'J1', end: 'J1', title: '주문수량' },
    { start: 'K1', end: 'K1', title: '공급가' },
    { start: 'L1', end: 'L1', title: '실결제가' },
    { start: 'M1', end: 'M1', title: '배송완료일' },
    { start: 'N1', end: 'N1', title: '교환반품완료일' },
    { start: 'O1', end: 'O1', title: '교환반품 상태' },
    { start: 'P1', end: 'P1', title: '요청수량' },
    { start: 'Q1', end: 'Q1', title: '반품 공급가' },
  ],
};

export const SHEET_교환반품_배송비_다운로드_ADMIN = {
  name: '교환반품 배송비',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '거래처코드' },
    { start: 'B1', end: 'B1', title: '거래처명' },
    { start: 'C1', end: 'C1', title: '접수일' },
    { start: 'D1', end: 'D1', title: '주문일' },
    { start: 'E1', end: 'E1', title: '교환반품 완료일' },
    { start: 'F1', end: 'F1', title: '접수번호' },
    { start: 'G1', end: 'G1', title: '주문번호' },
    { start: 'H1', end: 'H1', title: '사유' },
    { start: 'I1', end: 'I1', title: '배송비' },
  ],
};
