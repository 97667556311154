import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Typo } from '@teampetfriends/react-ui';

import { postChangePassword } from '@auth/FindPassword/apis';
import {
  PASSWORD_CONFIRM_ERROR_MESSAGE,
  PASSWORD_CONFIRM_MESSAGE,
  PASSWORD_EMPTY_ERROR_MESSAGE,
  PASSWORD_ERROR_MESSAGE,
} from '@auth/FindPassword/constant';
import { MD_EMAIL } from '@auth/Login/constant';
import Button from '@components/Button';
import { Input } from '@components/Input';
import { toastify } from '@components/Toast';
import { useMutation } from '@tanstack/react-query';
import { tokenRefreshInterceptor } from '@utils/interceptor';
import { getTokenData } from '@utils/token';

interface PasswordError {
  passwordError: string;
  passwordConfirmError: string;
}

function PasswordForm() {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState<PasswordError>({
    passwordError: PASSWORD_EMPTY_ERROR_MESSAGE,
    passwordConfirmError: '',
  });
  const [isError, setIsError] = useState(false);

  const userId = getTokenData()?.user_account ?? '';

  const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+[\]{}|;:',.<>?/\\])(?!.*\s).{8,20}$/;

  const { mutate } = useMutation(postChangePassword, {
    onSuccess: async (res) => {
      if (res === 'ok') {
        const tokens = await tokenRefreshInterceptor();

        if (!tokens) throw new Error('토큰 발급 실패');

        toastify('비밀번호 변경이 완료되었습니다!', { position: 'bottom-center' });
        navigate('/', { replace: true });
      }
    },
    onError: (err) => {
      toastify('비밀번호 변경에 실패하였습니다.', { position: 'bottom-center', type: 'warning' });
      console.error(err);
    },
  });

  const validatePassword = (value: string) => regex.test(value);

  const validatePasswordConfirm = (value: string) => value === password;

  const onPasswordBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setPassword(value);
    if (!validatePassword(value)) {
      setIsError(true);
      setErrors((prevState) => ({
        ...prevState,
        passwordError: PASSWORD_ERROR_MESSAGE,
      }));
    } else {
      setIsError(false);
      setErrors((prevState) => ({
        ...prevState,
        passwordError: PASSWORD_CONFIRM_MESSAGE,
      }));
    }
  };

  const onPasswordConfirmBlurHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    const isPasswordConfirmValid = validatePasswordConfirm(value);
    setPasswordConfirm(value);
    setErrors((prevState) => ({
      ...prevState,
      passwordConfirmError: isPasswordConfirmValid ? '' : PASSWORD_CONFIRM_ERROR_MESSAGE,
    }));
  };

  const onSubmitHandler = (e: FormEvent) => {
    e.preventDefault();

    // TODO: 정산 배포 후 정산에서 사용하는 로그아웃 훅스로 변경예정
    if (!userId) {
      toastify('잘못된 접근입니다.', { type: 'warning' });
      navigate('/login', { replace: true });
      return false;
    }

    const isPasswordValid = validatePassword(password);
    const isPasswordConfirmValid = validatePasswordConfirm(passwordConfirm);
    if (password.length === 0) {
      setIsError(true);
      setErrors((prevState) => ({
        ...prevState,
        passwordError: PASSWORD_EMPTY_ERROR_MESSAGE,
      }));
    }
    setErrors((prevState) => ({
      ...prevState,
      passwordConfirmError: isPasswordConfirmValid ? '' : PASSWORD_CONFIRM_ERROR_MESSAGE,
    }));
    if (isPasswordValid && isPasswordConfirmValid) {
      mutate({ account: userId as string, password });
    }
  };

  return (
    <div className='rounded-[1rem] bg-white p-[4rem] flex items-center flex-col w-full shadow-md'>
      <Typo
        as='h3'
        css={{
          fontSize: '2.4rem',
          fontWeight: 700,
          marginBottom: '.8rem',
        }}
      >
        비밀번호를 변경해주세요!
      </Typo>
      <Typo
        as='p'
        css={{
          fontSize: '$14',
          marginBottom: '$16',
          textAlign: 'center',
          color: 'var(--color-black-400)',
          lineHeight: '2.1rem',
        }}
      >
        보다 안전한 사용을 위하여 <br />
        담당 MD분께 전달받은 비밀번호를 변경해야 합니다.
      </Typo>

      <form onSubmit={onSubmitHandler}>
        <Input
          className='w-full h-[6rem] border border-solid border-[#cfd4d9] rounded-[.4rem] mb-[1.4rem] pl-[2rem]'
          disabled
          value={userId as string}
        />
        <Input
          type='password'
          placeholder='새로운 비밀번호를 입력해주세요.'
          autoComplete='new-password'
          className='w-full h-[6rem] border border-solid border-[#cfd4d9] rounded-[.4rem] mb-[1rem] pl-[2rem]'
          error={errors.passwordError}
          errorStyle={{
            whiteSpace: 'pre-wrap',
            display: 'inline-block',
            marginBottom: '1.1rem',
            color: isError ? 'var(--color-red-100)' : 'var(--color-blue-100)',
          }}
          isGuide={!isError}
          onBlur={onPasswordBlurHandler}
        />
        <Input
          type='password'
          placeholder='새로운 비밀번호를 확인해주세요.'
          autoComplete='new-password'
          className='w-full h-[6rem] border border-solid border-[#cfd4d9] rounded-[.4rem] mb-[1rem] pl-[2rem]'
          error={errors.passwordConfirmError}
          onBlur={onPasswordConfirmBlurHandler}
        />

        <Flex direction='column' css={{ marginTop: '2rem' }}>
          <Button type='submit' size='large' color='black' width='100%'>
            <Typo as='span' css={{ fontWeight: 700, fontSize: '1.8rem', color: 'inherit' }}>
              변경하기
            </Typo>
          </Button>
        </Flex>
      </form>

      <Typo
        as='span'
        css={{
          margin: '1.4rem 0 1.6rem',
          fontSize: '$13',
          color: '#666',
        }}
      >
        아이디/비밀번호를 잊으셨다면 담당 MD분께 연락 바랍니다.
      </Typo>

      <div className='w-full pt-[1.8rem] pl-[2rem] pb-[1.4rem] bg-grey-30'>
        <strong className='block text-[1.6rem] mb-[.4rem]'>입점문의를 도와드려요!</strong>
        <Flex align='center' css={{ columnGap: '1rem' }}>
          <Typo
            as='span'
            css={{ fontSize: '1.3rem', color: 'var(--color-black-400)', lineHeight: '2rem' }}
          >{`펫프렌즈 입점 문의 신청: ${MD_EMAIL}`}</Typo>
          <button
            type='button'
            className='h-[2.4rem] px-[.4rem] rounded-[.4rem] border border-blue-50 text-blue-50'
          >
            메일복사
          </button>
        </Flex>
      </div>
    </div>
  );
}

export default PasswordForm;
