/*
role
PURCHASE  ("매입")
VENDOR  ("업체배송")
CONSIGNMENT  ("위탁배송")
*/

import { CLAIM_INQUIRE_INITIAL_STATE } from '@claim/ClaimInquire/module/constants';
import { COUPON_REPORT_INITIAL_PARAMS } from '@coupon/CouponReport/modules/constant';
import { getCouponRequestInitialState } from '@coupon/CouponRequest/modules/constant';
import { getDeliveryManageInitialState } from '@delivery/DeliveryManage/modules/constant';
import { getInboundRequestInitialState } from '@inbound/InboundRequest/modules/constant';
import { getInformationRequestInitialState } from '@information/InformationRequest/modules/constant';
import { ITEMS_INQUIRE_INITIAL_STATE } from '@items/ItemsInquire/modules/constant';
import { NOTICE_INQUIRE_INITIAL_STATE } from '@notice/NoticeInquire/modules/constant';
import { OUTBOUND_INQUIRE_INITIAL_STATE } from '@outbound/OutboundInquire/constant';
import { PRODUCT_INQUIRE_INITIAL_STATE } from '@product/ProductInquire/modules/constant';
import { getProductModifyInitialState } from '@product/ProductModifyRequest/constant';
import { PRODUCT_REQUEST_INITIAL_STATE } from '@product/ProductRequest/modules/constant';
import { getSettlementInitialState } from '@settlement/commons/modules/constant';
import { getSettlementStatementInitialState } from '@settlement/IntegrateSettlement/modules/constant';
import { STOCKS_INQUIRE_INITIAL_STATE } from '@stocks/StocksInquire/modules/constant';

import type { Menu } from './interfaces';

export const getAdminMenus = (): Menu[] => {
  return [
    {
      name: '상품',
      ico: 'MenuProduct',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '상품 조회',
          path: '/product/list',
          visible: true,
          params: PRODUCT_INQUIRE_INITIAL_STATE,
        },
        {
          name: '상품 요청 관리',
          path: '/admin/request/list',
          visible: true,
          params: PRODUCT_REQUEST_INITIAL_STATE,
        },
      ],
    },
    {
      name: '업체배송',
      ico: 'MenuDelivery',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '배송관리',
          path: '/delivery/manage',
          visible: true,
          params: getDeliveryManageInitialState(),
        },
        {
          name: '정산 내역',
          path: '/vendor-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '판매자 심쿵배송',
      ico: 'MenuDelivery',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '출고현황',
          path: '/outbound/list',
          visible: true,
          params: OUTBOUND_INQUIRE_INITIAL_STATE,
        },
        {
          name: '품목관리',
          path: '/items/list',
          visible: true,
          params: ITEMS_INQUIRE_INITIAL_STATE,
        },
        {
          name: '입고 요청/내역',
          path: '/inbound/request',
          visible: true,
          params: getInboundRequestInitialState(),
        },
        {
          name: '재고조회',
          path: '/stocks/list',
          visible: true,
          params: STOCKS_INQUIRE_INITIAL_STATE,
        },
        {
          name: '정산내역',
          path: '/consignment-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '교환/반품',
      ico: 'MenuAnnounce',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '교환/반품',
          path: '/exchange-return',
          visible: true,
          params: CLAIM_INQUIRE_INITIAL_STATE,
        },
      ],
    },
    {
      name: '쿠폰',
      ico: 'MenuCoupon',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '쿠폰 요청 관리',
          path: '/coupon/request',
          visible: true,
          params: getCouponRequestInitialState(),
        },
        {
          name: '쿠폰 리포트',
          path: '/coupon/report',
          visible: true,
          params: COUPON_REPORT_INITIAL_PARAMS,
        },
        {
          name: '정산내역',
          path: '/coupon-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '정산',
      ico: 'MenuSettlement',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '정산내역',
          path: '/integrate-settlement/list',
          visible: true,
          params: getSettlementStatementInitialState(),
        },
      ],
    },
    {
      name: '공지사항',
      ico: '',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '/notice/list',
      params: NOTICE_INQUIRE_INITIAL_STATE,
      subMenu: [],
    },
    {
      name: '판매자 정보 요청 관리',
      ico: '',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '/admin/information/request',
      params: getInformationRequestInitialState(),
      subMenu: [],
    },
  ];
};

export const getVendorMenus = (): Menu[] => {
  return [
    {
      name: '상품',
      ico: 'MenuProduct',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        { name: '상품등록', path: '/product/form', visible: true },
        {
          name: '상품 조회',
          path: '/product/list',
          visible: true,
          params: PRODUCT_INQUIRE_INITIAL_STATE,
        },
        {
          name: '상품정보 수정 요청',
          path: '/vendor/product/modify',
          visible: true,
          params: getProductModifyInitialState(),
        },
        { name: '판매 상품 연결', path: '/product/package', visible: true },
      ],
    },
    {
      name: '업체배송',
      ico: 'MenuDelivery',
      visible: true,
      role: ['VENDOR'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '배송관리',
          path: '/delivery/manage',
          visible: true,
          params: getDeliveryManageInitialState(),
        },
        {
          name: '정산 내역',
          path: '/vendor-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '판매자 심쿵배송',
      ico: 'MenuDelivery',
      visible: true,
      role: ['CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '출고현황',
          path: '/outbound/list',
          visible: true,
          params: OUTBOUND_INQUIRE_INITIAL_STATE,
        },
        {
          name: '품목관리',
          path: '/items/list',
          visible: true,
          params: ITEMS_INQUIRE_INITIAL_STATE,
        },
        {
          name: '입고 요청/내역',
          path: '/inbound/request',
          visible: true,
          params: getInboundRequestInitialState(),
        },
        {
          name: '재고조회',
          path: '/stocks/list',
          visible: true,
          params: STOCKS_INQUIRE_INITIAL_STATE,
        },
        {
          name: '정산내역',
          path: '/consignment-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '교환/반품',
      ico: 'MenuAnnounce',
      visible: true,
      role: ['VENDOR', 'CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '교환/반품',
          path: '/exchange-return',
          visible: true,
          params: CLAIM_INQUIRE_INITIAL_STATE,
        },
      ],
    },
    {
      name: '쿠폰',
      ico: 'MenuCoupon',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '쿠폰 관리',
          path: '/coupon/request',
          visible: true,
          params: getCouponRequestInitialState(),
        },
        {
          name: '쿠폰 리포트',
          path: '/coupon/report',
          visible: true,
          params: COUPON_REPORT_INITIAL_PARAMS,
        },
        {
          name: '정산내역',
          path: '/coupon-settlement/list',
          visible: true,
          params: getSettlementInitialState(),
        },
      ],
    },
    {
      name: '정산',
      ico: 'MenuSettlement',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '',
      subMenu: [
        {
          name: '정산내역',
          path: '/integrate-settlement/list',
          visible: true,
          params: getSettlementStatementInitialState(),
        },
      ],
    },
    {
      name: '공지사항',
      ico: '',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '/notice/list',
      params: NOTICE_INQUIRE_INITIAL_STATE,
      subMenu: [],
    },
    {
      name: '판매자 정보',
      ico: '',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '/information/vendor',
      params: {},
      subMenu: [],
    },
    {
      name: '담당자 정보',
      ico: '',
      visible: true,
      role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
      isNew: false,
      path: '/information/manager',
      params: {},
      subMenu: [],
    },
  ];
};
