import { Flex } from '@teampetfriends/react-ui';

import { type DeliveryStatusCode } from '@delivery/DeliveryManage/modules/apis';
import { getDeliveryManageInitialState } from '@delivery/DeliveryManage/modules/constant';
import { useRoute } from '@hooks/useRoute';
import { getCalcDate } from '@utils/util';

import { useGetDeliverySummaryWithDashboard } from '../apis';
import { useAuthCheck } from '../hooks/useAuthCheck';

import { Board, BoardTitle } from './Board';
import InlineItem from './InlineItem';

function DeliverySection() {
  const { push } = useRoute();
  const { data } = useGetDeliverySummaryWithDashboard();

  const checkAuth = useAuthCheck();

  const {
    orderCompletedCount,
    deliveryDraftCount,
    deliveryProcessCount,
    delayedDeliveryCompleteCount,
  } = data;

  const onClickNavigateDeliveryPage = (endPointKey: DeliveryStatusCode, delayedDelivery = 'N') =>
    checkAuth(['VENDOR'], () => {
      const defaultParams = getDeliveryManageInitialState();

      defaultParams.startDate = getCalcDate(new Date(), 1, 'month');
      defaultParams.endPointKey = endPointKey;
      defaultParams.delayedDelivery = delayedDelivery;

      push('/delivery/manage', defaultParams);
    });

  return (
    <Board css={{ background: '#F9F9FA', flex: 1, height: '100%' }}>
      <BoardTitle title='주문/배송' description='최근 1개월 기준' css={{ pb: '2rem' }} />
      <Flex as='ul' direction='column' css={{ mt: '3rem', rowGap: '1rem' }}>
        <InlineItem
          label='결제완료'
          value={orderCompletedCount}
          unit='건'
          onClick={() => onClickNavigateDeliveryPage(19)}
        />
        <InlineItem
          label='배송준비중'
          value={deliveryDraftCount}
          unit='건'
          onClick={() => onClickNavigateDeliveryPage(20)}
        />
        <InlineItem
          label='배송중'
          value={deliveryProcessCount}
          unit='건'
          onClick={() => onClickNavigateDeliveryPage(21)}
        />
        <InlineItem
          label='배송완료 지연'
          value={delayedDeliveryCompleteCount}
          unit='건'
          onClick={() => onClickNavigateDeliveryPage(21, 'Y')}
        />
      </Flex>
    </Board>
  );
}

export default DeliverySection;
