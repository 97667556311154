export const SHEET_물류비_배송비_다운로드_VENDOR = {
  name: '배송비',
  sheetFormat: [
    { start: 'A1', end: 'A2', title: '구분' },
    { start: 'B1', end: 'B2', title: '출고유형' },
    { start: 'C1', end: 'C2', title: '주문번호' },
    { start: 'D1', end: 'D2', title: '주문일자' },
    { start: 'E1', end: 'E2', title: '출고일자' },
    { start: 'F1', end: 'F2', title: '품목코드' },
    { start: 'G1', end: 'G2', title: '품목명' },
    { start: 'H1', end: 'H2', title: '부피(CBM)' },
    { start: 'I1', end: 'I2', title: '중량(KG)' },
    { start: 'J1', end: 'J2', title: '출고수량' },
    { start: 'K1', end: 'K2', title: '총 부피' },
    { start: 'L1', end: 'L2', title: '총 중량' },
    { start: 'M1', end: 'M2', title: '과금기준' },
    { start: 'N1', end: 'O1', title: '극소' },
    { start: 'N2', end: 'N2', title: '수량' },
    { start: 'O2', end: 'O2', title: '금액' },
    { start: 'P1', end: 'Q1', title: '소형' },
    { start: 'P2', end: 'P2', title: '수량' },
    { start: 'Q2', end: 'Q2', title: '금액' },
    { start: 'R1', end: 'S1', title: '중형' },
    { start: 'R2', end: 'R2', title: '수량' },
    { start: 'S2', end: 'S2', title: '금액' },
    { start: 'T1', end: 'U1', title: '대형1' },
    { start: 'T2', end: 'T2', title: '수량' },
    { start: 'U2', end: 'U2', title: '금액' },
    { start: 'V1', end: 'W1', title: '대형2' },
    { start: 'V2', end: 'V2', title: '수량' },
    { start: 'W2', end: 'W2', title: '금액' },
    { start: 'X1', end: 'Y1', title: '이형' },
    { start: 'X2', end: 'X2', title: '수량' },
    { start: 'Y2', end: 'Y2', title: '금액' },
    { start: 'Z1', end: 'Z2', title: '배송비합계' },
  ],
};

export const SHEET_물류비_보관비_다운로드_VENDOR = {
  name: '보관비',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '품목코드' },
    { start: 'B1', end: 'B1', title: '품목명' },
    { start: 'C1', end: 'C1', title: '보관온도' },
    { start: 'D1', end: 'D1', title: 'Loc.no' },
    { start: 'E1', end: 'E1', title: '보관유형' },
    { start: 'F1', end: 'F1', title: '보관일수' },
    { start: 'G1', end: 'G1', title: '단가' },
    { start: 'H1', end: 'H1', title: '보관비' },
  ],
};

// ----

export const SHEET_물류비_배송비_다운로드_ADMIN = {
  name: '배송비',
  sheetFormat: [
    { start: 'A1', end: 'A2', title: '거래처코드' },
    { start: 'B1', end: 'B2', title: '거래처명' },
    { start: 'C1', end: 'C2', title: '구분' },
    { start: 'D1', end: 'D2', title: '출고유형' },
    { start: 'E1', end: 'E2', title: '주문번호' },
    { start: 'F1', end: 'F2', title: '주문일자' },
    { start: 'G1', end: 'G2', title: '출고일자' },
    { start: 'H1', end: 'H2', title: '품목코드' },
    { start: 'I1', end: 'I2', title: '품목명' },
    { start: 'J1', end: 'J2', title: '부피(CBM)' },
    { start: 'K1', end: 'K2', title: '중량(KG)' },
    { start: 'L1', end: 'L2', title: '출고수량' },
    { start: 'M1', end: 'M2', title: '총 부피' },
    { start: 'N1', end: 'N2', title: '총 중량' },
    { start: 'O1', end: 'O2', title: '과금기준' },
    { start: 'P1', end: 'Q1', title: '극소' },
    { start: 'P2', end: 'P2', title: '수량' },
    { start: 'Q2', end: 'Q2', title: '금액' },
    { start: 'R1', end: 'S1', title: '소형' },
    { start: 'R2', end: 'R2', title: '수량' },
    { start: 'S2', end: 'S2', title: '금액' },
    { start: 'T1', end: 'U1', title: '중형' },
    { start: 'T2', end: 'T2', title: '수량' },
    { start: 'U2', end: 'U2', title: '금액' },
    { start: 'V1', end: 'W1', title: '대형1' },
    { start: 'V2', end: 'V2', title: '수량' },
    { start: 'W2', end: 'W2', title: '금액' },
    { start: 'X1', end: 'Y1', title: '대형2' },
    { start: 'X2', end: 'X2', title: '수량' },
    { start: 'Y2', end: 'Y2', title: '금액' },
    { start: 'Z1', end: 'AA1', title: '이형' },
    { start: 'Z2', end: 'Z2', title: '수량' },
    { start: 'AA2', end: 'AA2', title: '금액' },
    { start: 'AB1', end: 'AB2', title: '배송비합계' },
  ],
};

export const SHEET_물류비_보관비_다운로드_ADMIN = {
  name: '보관비',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '거래처코드' },
    { start: 'B1', end: 'B1', title: '거래처명' },
    { start: 'C1', end: 'C1', title: '기준일자' },
    { start: 'D1', end: 'D1', title: '품목코드' },
    { start: 'E1', end: 'E1', title: '품목명' },
    { start: 'F1', end: 'F1', title: '보관온도' },
    { start: 'G1', end: 'G1', title: 'Loc.no' },
    { start: 'H1', end: 'H1', title: '보관유형' },
    { start: 'I1', end: 'I1', title: '보관수량' },
    { start: 'J1', end: 'J1', title: '보관일수' },
    { start: 'K1', end: 'K1', title: '단가' },
    { start: 'L1', end: 'L1', title: '보관비' },
  ],
};
