import { client } from '@utils/client';
import { getTokenData } from '@utils/token';

export const settlementClient = (isLoading?: boolean) => {
  const checkUser = getTokenData();
  const isVendor = checkUser?.is_vendor;

  const api = client(isLoading);

  api.defaults.baseURL = `${import.meta.env.VITE_BASE_SETTLEMENT_URL}${
    isVendor ? '/partner' : '/admin'
  }`;

  return api;
};

export const settlementClientWithEnum = (isLoading?: boolean) => {
  const api = client(isLoading);

  api.defaults.baseURL = import.meta.env.VITE_BASE_SETTLEMENT_URL;

  return api;
};
