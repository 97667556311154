import { Typo } from '@teampetfriends/react-ui';

import { getCalcDate } from '@utils/util';

import type { InboundRequestParams } from './interfaces';

export const getInboundRequestInitialState = (): InboundRequestParams => {
  const startDate = getCalcDate(new Date(), 14, 'day');
  const endDate = getCalcDate(new Date(), 14, 'day', 'add');

  return {
    dateCondition: 'REQUEST_DAY',
    startDate,
    endDate,
    inboundStatuses: null,
    pageNumber: 1,
    pageSize: 10,
  };
};

export const INBOUND_UPLOAD_COLUMNS_KEY = {
  품목코드: 'productItemId',
  '품목 바코드': 'itemBarcode',
  입고수량: 'requestQuantity',
  유통기한: 'shelfLifeDay',
  비고: 'remark',
};

export const INBOUND_REGISTER_INFO_INITIAL_STATE = {
  centerId: 12,
  requestDay: new Date(),
  transportType: 'TRUCK',
  transportQuantity: '',
  transportCargoType: 'BOX',
  transportCargoQuantity: '',
  remark: '',
};

export const INBOUND_REGISTER_CAUTIONS = [
  '[품목관리]에서 마스터 정보가 등록되어야 합니다.',
  '입고요청일은 물류센터 상황에 따라 조정될 수 있습니다.',
  ' 유통기한이 없는 품목의 경우 “9999-12-31”로 입력해주시고, 복수의 유통기한이 존재한다면 비고란을 활용해주세요.',
  '요청해주신 내역은 펫프렌즈 승인 후 입고가 가능합니다. 임의로 입고하시는 경우 회송될 수 있습니다.',
  <>
    <Typo as='span' css={{ fontSize: 'inherit', color: '#EA306F', fontWeight: 600 }}>
      펫프렌즈 입고가이드
    </Typo>
    를 꼭 준수해주세요.
    <br />
    가이드 미준수에 의해 발생하는 비용 및 업무 지연 등에 대한 책임은 판매자에게 있습니다.
  </>,
];

export const INBOUND_DETAIL_ITEM_AND_PRICE_SUMMARY_UNIT_TABLE = {
  productItemQuantity: 'SKU',
  requestQuantity: 'EA',
  requestPrice: '원',
  receivedQuantity: 'EA',
  receivedPrice: '원',
};
