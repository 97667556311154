export const SHEET_결제대금_다운로드_ADMIN = {
  name: '주문 목록',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '거래처코드' },
    { start: 'B1', end: 'B1', title: '거래처명' },
    { start: 'C1', end: 'C1', title: '주문일자' },
    { start: 'D1', end: 'D1', title: '주문번호' },
    { start: 'E1', end: 'E1', title: '상품명' },
    { start: 'F1', end: 'F1', title: '옵션명' },
    { start: 'G1', end: 'G1', title: '옵션값' },
    { start: 'H1', end: 'H1', title: '수량' },
    { start: 'I1', end: 'I1', title: '판매가' },
    { start: 'J1', end: 'J1', title: '할인액/율' },
    { start: 'K1', end: 'K1', title: '공급가' },
    { start: 'L1', end: 'L1', title: '실결제가' },
    { start: 'M1', end: 'M1', title: '주문상태' },
    { start: 'N1', end: 'N1', title: '취소사유' },
    { start: 'O1', end: 'O1', title: '취소상세사유' },
    { start: 'P1', end: 'P1', title: '취소일' },
    { start: 'Q1', end: 'Q1', title: '배송완료일' },
    { start: 'R1', end: 'R1', title: '배송완료 처리일' },
  ],
};

export const SHEET_결제대금_다운로드_VENDOR = {
  name: '주문 목록',
  sheetFormat: [
    { start: 'A1', end: 'A1', title: '주문일자' },
    { start: 'B1', end: 'B1', title: '주문번호' },
    { start: 'C1', end: 'C1', title: '상품명' },
    { start: 'D1', end: 'D1', title: '옵션명' },
    { start: 'E1', end: 'E1', title: '옵션값' },
    { start: 'F1', end: 'F1', title: '수량' },
    { start: 'G1', end: 'G1', title: '판매가' },
    { start: 'H1', end: 'H1', title: '할인액/율' },
    { start: 'I1', end: 'I1', title: '공급가' },
    { start: 'J1', end: 'J1', title: '실결제가' },
    { start: 'K1', end: 'K1', title: '주문상태' },
    { start: 'L1', end: 'L1', title: '취소사유' },
    { start: 'M1', end: 'M1', title: '취소상세사유' },
    { start: 'N1', end: 'N1', title: '취소일' },
    { start: 'O1', end: 'O1', title: '배송완료일' },
    { start: 'P1', end: 'P1', title: '배송완료 처리일' },
  ],
};
