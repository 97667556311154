import { getCalcDate } from '@utils/util';

import type { InformationRequestParams } from './interfaces';

export const getInformationRequestInitialState = (): InformationRequestParams => {
  const startDate = getCalcDate(new Date(), 1, 'month');

  return {
    pageNumber: 1,
    pageSize: 10,
    startDate,
    endDate: new Date(),
    actions: null,
    statuses: null,
    searchCondition: 'VENDOR_NAME',
    searchContents: '',
  };
};
