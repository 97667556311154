import { Flex, Typo } from '@teampetfriends/react-ui';

import type { CellProps, HeaderProps } from '@components/Table';

const MAPPING_HEADER_LABELS = {
  sellingPrice: '판매가',
  supplyPrice: '공급가액',
  detailPrice: '상세내역',
  deductionPrice: '공제금액(-)',
  refundPrice: '환급금액(-)',
};

export function RequiredHeader<T>({ header }: HeaderProps<T, unknown>) {
  const headerName = MAPPING_HEADER_LABELS[header.column.id as keyof typeof MAPPING_HEADER_LABELS];

  return (
    <Flex align='center' justify='center'>
      <Typo css={{ fontSize: '1.3rem', fontWeight: 400 }}>{headerName}</Typo>
      <sup className='text-[1.8rem] top-[-.5rem] right-[-.1rem] text-red-300'>*</sup>
    </Flex>
  );
}

export function NumberAndUnitCell<T>({
  row,
  column,
  type,
}: CellProps<T> & { type: '%' | '건' | '개' }) {
  const value = row.getValue(column.id);

  const makePriceWonCell = (value: unknown) => {
    if (typeof value === 'number') return `${value.toLocaleString()}${type}`;
    if (!value) return '-';
    return String(value);
  };

  return (
    <Flex direction='column' align='center' css={{ rowGap: '.1rem' }}>
      <p>{makePriceWonCell(value)}</p>
    </Flex>
  );
}

export function NumberAndWonCell<T>({ row, column }: CellProps<T>) {
  const value = row.getValue(column.id);

  const makePriceWonCell = (value: unknown) => {
    if (typeof value === 'number') return `${value.toLocaleString()}원`;
    if (!value) return '-';
    return String(value);
  };

  return (
    <Flex direction='column' align='center' css={{ rowGap: '.1rem' }}>
      <p>{makePriceWonCell(value)}</p>
    </Flex>
  );
}

export function EllipsisTextCell<T>({ row, column }: CellProps<T>) {
  const value = row.getValue(column.id) as string;

  return (
    <Flex direction='column' align='center'>
      <Typo
        css={{
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: '#000',
        }}
      >
        {value}
      </Typo>
    </Flex>
  );
}
