import { useNavigate } from 'react-router-dom';

import { useSetAtom } from 'jotai';

import { logout as adminLogout } from '@auth/Login/firebase/helper';
import { optionAtom } from '@hooks/useOptions/useOptions';
import { useAuthInfo } from '@hooks/useSyncAuth';
import { optionAtomWithSettlement } from '@settlement/IntegrateSettlement/modules/hooks/useOptionsWithSettlement/useOptionsWithSettlement';
import { getTokenData } from '@utils/token';

export const useLogout = () => {
  const userData = getTokenData();
  const navigate = useNavigate();
  const setResetOptions = useSetAtom(optionAtom);
  const setResetSettlementOptions = useSetAtom(optionAtomWithSettlement);

  const { remove } = useAuthInfo(['accessToken']);

  const logout = () => {
    if (!userData?.is_vendor) adminLogout();

    remove();
    setResetOptions(null);
    setResetSettlementOptions(null);

    navigate('/login', { replace: true });
  };

  return logout;
};
